import React, { useState } from "react";
import Eye from '../../assets/icons/eye_icon.svg';
import CancelClear from '../../assets/icons/cancel_clear_icon.svg';
import { Form } from 'react-bootstrap';
import './styles.scss';

type Input = {
    onChange: () => void;
    value: string;
    error: object;
    placeholder: string,
    passwordCheck: boolean,
    defaultType: string,
}
export const AuthInput = ({onChange, value, passwordCheck, error, placeholder, defaultType}: Input) => {
    const [ type, setType] = useState(defaultType);
    const togglePassword = () => type === "password" ? setType("text") : setType("password");
    return (
        <div className="auth-input">
            <Form.Control  
                className={ error ? 'form-control-error' : ''}                                                             
                onChange={onChange} value={value} type={type}                                                                                
                placeholder={placeholder}
            />
            <div className="auth-input-icons">
                { value ? (<img src={CancelClear as string} alt="CancelClear" onClick={() => onChange()} className='auth-input-icons-cancel' />) : null}
                { value && passwordCheck ? (<img src={Eye as string} alt="Eye" onClick={() => togglePassword()} className='auth-input-icons-eye'/>) : null }
            </div>
        </div>
    )
}