import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { DEVICE_NAME, SERVER } from '../constants';
export interface MyData {
  name: string;
  email: string;
  password: string;
  message: string;
  user: {
    name: string;
    email: string;
    password: string;
  }
}
export interface Auth {
  name: string,
  email: string,
  password: string,
  message: string,
}
export const signupUser = createAsyncThunk<MyData,Auth>(
  'users/signupUser',
  async ({ name, email, password }, thunkAPI) => {
    try {
      const response = await fetch(
        'https://mock-user-auth-server.herokuapp.com/api/v1/users',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            email,
            password,
          }),
        }
      );
      let data = await response.json();
      console.log('data', data);

      if (response.status === 200) {
        localStorage.setItem('token', data.token);
        return { ...data, username: name, email: email };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const loginUser = createAsyncThunk<MyData, Auth>(
  'users/login',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://${SERVER}/api/sanctum/token`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
            device_name:DEVICE_NAME
          }),
        }
      );
      let data = await response.json();
      console.log('response', data);
      if (response.status === 200) {
        localStorage.setItem('token', data.token);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'users/logout',
  async () => {
    try {
      const response = await fetch(
        `https://${SERVER}/api/logout`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        }
      );
      let data = await response.json();
      console.log('response', data);
      localStorage.removeItem('token');
      return data;
    } catch (e) {
      console.log('Error', e.response.data);
      
    }
  }
);

/*export const fetchUserBytoken = createAsyncThunk(
  'users/fetchUserByToken',
  async ({ token }, thunkAPI) => {
    try {
      const response = await fetch(
        'https://mock-user-auth-server.herokuapp.com/api/v1/users',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      console.log('data', data, response.status);

      if (response.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);*/

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    username: '',
    email: '',
    isFetching: false,
    message: '',
    isSuccess: false,
    isError: false,
    isSuccessLogout: false,
    isErrorLogout: false,
    errorsLogin: null,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isErrorLogout = false;
      state.isSuccessLogout = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signupUser.fulfilled, (state, { payload }) => {
      console.log('payload', payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.user.email;
      state.username = payload.user.name;
    })
    .addCase(signupUser.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(signupUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      //state.errorMessage = payload.message;
    })
    .addCase(loginUser.fulfilled, (state, { payload }) => {
      state.email = payload.email;
      state.username = payload.name;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    })
    .addCase(loginUser.rejected, (state, { payload }: any) => {
      console.log('payload', payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
      state.errorsLogin = payload.errors;
    })
    .addCase(loginUser.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(logoutUser.fulfilled, (state) => {
      state.isFetching = false;
      state.isSuccessLogout = true;
      return state;
    })
    .addCase(logoutUser.rejected, (state, { payload }: any) => {
      console.log('payload', payload);
      state.isFetching = false;
      state.isErrorLogout = true;
      state.errorMessage = payload.message;
    })
    .addCase(logoutUser.pending, (state) => {
      state.isFetching = true;
    })
    
   /* [fetchUserBytoken.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchUserBytoken.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;

      state.email = payload.email;
      state.username = payload.name;
    },
    [fetchUserBytoken.rejected]: (state) => {
      console.log('fetchUserBytoken');
      state.isFetching = false;
      state.isError = true;
    },*/
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state: RootState) => state.user;