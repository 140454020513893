import { configureStore, Action } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { ThunkAction } from 'redux-thunk'

import rootReducer from './rootReducer'
import logger from 'redux-logger';

const middleware = (getDefaultMiddleware: Function) =>
  getDefaultMiddleware().concat(logger);

const store = configureStore({
    reducer: rootReducer,
    middleware,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch()
export type AppThunk = ThunkAction<void, RootState, unknown, Action>

export default store