import React, {Component} from "react"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import Header from './components/Landing/Header';
import { hot } from "react-hot-loader";
import './sass/App.scss';

class App extends Component {
    render() {
        return (
            <div className="App">
                <Router>
                    <Routes>
                        <Route index element={<Header/>}/>
                        <Route element={<Header/>} path='/'/>
                    </Routes>
                </Router>
            </div>
        );
    }
}
export default hot(module)(App);
