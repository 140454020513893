import React, { useEffect, useState } from "react";
import { Container, Navbar, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, userSelector, clearState, MyData } from '../../../services/auth';
import Login from "../../Login"
import Logo from '../../../assets/icons/logo.svg';
import Wallet from '../../../assets/icons/wallet_icon.svg';
import UserPhoto from '../../../assets/icons/user_no_photo.svg';
import Star from '../../../assets/icons/Star 1.svg';
import Profile from '../../../assets/icons/profile.svg';
import People from '../../../assets/icons/people.svg';
import Logout from '../../../assets/icons/logout.svg';
import './styles.scss';

const Header = () => {
    const [showLogin, setShowLogin] = useState(false);
    const dispatch = useDispatch();
    const handleClose = () => setShowLogin(false);
    const handleShow = () => setShowLogin(true);
    const navigate = useNavigate();
    const login = (<Login show={showLogin} handleClose={handleClose}/>)
    const { isSuccessLogout, isErrorLogout } = useSelector(
        userSelector
      );
    const logout = () => {
        dispatch(logoutUser())
    }
    useEffect(()=>{
        dispatch(clearState());
    },[isSuccessLogout])
    const loginBtns = (
        <>
            <button className="login" onClick={handleShow}>Log in</button>
            <button className="signup">Sign up</button>
        </>
    );
    const loggedIn = (
        <>
            <p className="wallet mb-0 me-4"><img className="me-2" src={Wallet as string} alt="wallet" />$HRM 0.00</p> 
            <NavDropdown 
                title={<span className="text-black email"><img src={UserPhoto as string} className='me-2' alt='photo'/>dummy@mail.com   </span>} 
                align={{ lg: 'end' }} 
                id="basic-nav-dropdown"
            >
                <NavDropdown.Item href="#" className='user-info email'><img src={UserPhoto as string} className='me-2' alt='photo'/>dummy@mail.com </NavDropdown.Item>
                <NavDropdown.Item href="#"><img src={Star as string} alt='star'/>Quests</NavDropdown.Item>
                <NavDropdown.Item href="#"><img src={People as string} alt='People'/>Refferals</NavDropdown.Item>
                <NavDropdown.Item href="#"><img src={Profile as string} alt='Profile'/>Profile</NavDropdown.Item>
                <NavDropdown.Item href="#" onClick={()=>logout()}><img src={Logout as string} alt='Logout'/>Logout</NavDropdown.Item>
            </NavDropdown>   
        </>
    );
    return (
        <>
            <Navbar bg="white" >
                <Container className="ms-5 ps-0">
                    <Navbar.Brand href="#"><img src={Logo as string} alt='hrmny' /></Navbar.Brand>
                </Container>
                <Navbar className="me-4">
                    {localStorage.getItem('token') ? loggedIn :loginBtns}
                </Navbar>
            </Navbar>
            {login}
        </>

    )
}

export default Header;