import React, { useEffect } from 'react';
import { useForm, SubmitHandler, Controller  } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, userSelector, clearState, MyData } from '../../services/auth';
import { Form, Modal } from 'react-bootstrap';
import Google from '../../assets/icons/google_icon.svg';
import { AuthInput } from '../../containers/AuthInpit';
import './styles.scss';

type LoginProps = {
  show: boolean;
  handleClose: () => void
}

const Login = ({show, handleClose}:LoginProps) => {
  const dispatch = useDispatch();
  const { setError, handleSubmit, control, reset,formState: {errors} } = useForm();
  const { isSuccess, isError, errorsLogin } = useSelector(
    userSelector
  );
  const onSubmit: SubmitHandler<MyData> = (data) => {
    dispatch(loginUser(data));
    
  };
  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isError) {
      Object.keys(errorsLogin).forEach( (field) => setError(field, {
        type: "server",        
        message: errorsLogin[field].join(' | ')})
      )
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      handleClose();
      reset();
    }
  }, [isError, isSuccess ]);

  const body = (
    <>
    <Form onSubmit={handleSubmit(onSubmit)}>
    <h1>Log in</h1>
      <Form.Group className="mb-3 mt-4" controlId="formEmail">
        <Form.Label>Email address</Form.Label>
        <Controller control={control} name="email"                                            
              defaultValue=""     
              rules={{ required: 'Email is required', pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              } }}
              render={({ field: { onChange, value }, fieldState: {error}}) => (                             
                <AuthInput
                  error={error}
                  onChange={onChange} 
                  defaultType='text' 
                  placeholder='Enter email' 
                  value={value} 
                  passwordCheck={false} 
                /> 
                ) 
              }
                />               
        {errors.email && (<p className='error-message'>{errors.email.message}</p> )}
      </Form.Group>
      
      
      <Form.Group className="mb-3" controlId="password">                                           
          <Form.Label>Password</Form.Label>                                                          
            <Controller control={control} name="password"                                            
              defaultValue="" 
              rules={{required:'incorrect password'}}                                                          
              render={({ field: { onChange, value }, fieldState: {error} }) => ( 
                <AuthInput 
                  error={error}
                  onChange={onChange} 
                  defaultType='password' 
                  placeholder='Enter password' 
                  value={value} 
                  passwordCheck/>                                                   
              )} />                                                                                  
          {errors.password && (<p className='error-message'>{errors.password.message}</p> )}                                                                  
        </Form.Group>
        <div className='link-position'>
          <a href="#" className="form-link">Forgot password</a>
        </div>
        <div className="d-grid">
          <button type="submit" className='login-submit'>Log in</button>
          <button className='google-submit'><img className='google-submit-img'src={Google as string} alt="google" />Sign in with Google</button>
        </div>
        <p className='paragraph-position'>Don’t have an account? <a href="#" className="form-link">Sign up</a></p>
    </Form>
    </>
  );

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        </Modal.Header>
      <Modal.Body>
      {body}
      </Modal.Body>
    </Modal>
  );
};

export default Login;